<template>
  <section class="bg-dark">
    <div class="container py-4">

      <div class="row">
        <div class="col-12 col-md-6">
          <a href="/video/ninja" title="Глядзець «Чарапашак-ніндзя» онлайн па-беларуску">
            <div class="align-items-center mt-2 mb-5">
              <img src="../../assets/img/pic_ninja.jpg" alt="Чарапашкі-ніндзя" class="img-thumbnail">
              <h3 class="mt-1 text-center b-title">
                «Чарапашкі-ніндзя»
              </h3>
            </div>
          </a>
        </div>

        <div class="col-12 col-md-6">
          <a href="/video/barakamon" title="Глядзець «Баракамон» онлайн па-беларуску">
            <div class="align-items-center mt-2 mb-5">
              <img src="../../assets/img/pic_barakamon.png" alt="Баракамон" class="img-thumbnail">
              <h3 class="mt-1 text-center b-title">
                «Баракамон»
              </h3>
            </div>
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <a href="/video/ronja" title="Глядзець «Роню» онлайн">
            <div class="align-items-center mt-2 mb-5">
              <img src="../../assets/img/pic_ronia.jpg" alt="Роня, дачка разбойніка" class="img-thumbnail">
              <h3 class="mt-1 text-center b-title">
                «Роня — дачка разбойніка»
              </h3>
            </div>
          </a>
        </div>

        <div class="col-12 col-md-4">
          <a href="/video/courage" title="Глядзець «Кураж» онлайн">
            <div class="align-items-center mt-2 mb-5">
              <img src="../../assets/img/pic_courage.jpg" alt="Кураж, палахлівы сабака" class="img-thumbnail">
              <h3 class="mt-1 text-center b-title">
                «Кураж — палахлівы сабака»
              </h3>
            </div>
          </a>
        </div>

        <div class="col-12 col-md-4">
          <a href="/video/mimimishki" title="Глядзець «Мімімішкі» онлайн">
            <div class="align-items-center mt-2 mb-5">
              <img src="../../assets/img/pic_mmm.jpg" alt="Мішкі-мімімішкі" class="img-thumbnail">
              <h3 class="mt-1 text-center b-title">
                «Мішкі-мімімішкі»
              </h3>
            </div>
          </a>
        </div>

        <div class="col-12 col-md-4">
          <a href="/video/papierki" title="Глядзець «Паперкі» онлайн">
            <div class="align-items-center mt-2 mb-5">
              <img src="../../assets/img/pic_ppr.jpg" alt="Паперкі" class="img-thumbnail">
              <h3 class="mt-1 text-center b-title">
                «Паперкі»
              </h3>
            </div>
          </a>
        </div>

        <div class="col-12 col-md-4">
          <a href="/video/paravozau" title="«Аркадзiй Паравозаў» онлайн">
            <div class="align-items-center mt-2 mb-5">
              <img src="../../assets/img/pic_default.png" alt="Аркадзiй Паравозаў" class="img-thumbnail">
              <h3 class="mt-1 text-center b-title">
                «Аркадзiй Паравозаў»
              </h3>
            </div>
          </a>
        </div>

        <div class="col-12 col-md-4">
          <a href="/video/kpatrul" title="«Казачны патруль» онлайн">
            <div class="align-items-center mt-2 mb-5">
              <img src="../../assets/img/pic_default.png" alt="Казачны патруль" class="img-thumbnail">
              <h3 class="mt-1 text-center b-title">
                «Казачны патруль»
              </h3>
            </div>
          </a>
        </div>
      </div>

    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.b-title {
  color: white;
}

a {
  color: white;
}
</style>
