<template>
  <PageVideoBase
      :img-thumb="require('@/assets/img/pic_ninja.jpg')"
      db-url="/db/movie/NinjaTortoise.json"/>
</template>

<script>
import PageVideoBase from "@/components/Pages/Video/PageVideoBase.vue";

export default {
  components: {
    PageVideoBase
  },
}
</script>
